import React from "react";
import Split from "../Split";
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { thumparallaxDown } from "../../common/thumparallax";

const MinimalArea2 = () => {

  const { t } = useTranslation();

  React.useEffect(() => {
    setTimeout(() => {
      thumparallaxDown();
    }, 1000);
  }, []);

  return (
      <section className="min-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="img">
                <img
                    className="thumparallax-down"
                    src="/img/office.jpg"
                    alt=""
                />
              </div>
            </div>

            <div className="col-lg-6 valign">
              <div className="content">
                <h4 className="color-font">{t('index.common.forBetterFuture')}</h4>

                <Split>
                  <p className="wow txt words chars splitting" data-splitting>
                    {t('index.about.part1')}
                  </p>

                  <p className="wow txt words chars splitting" data-splitting>
                    {t('index.about.part2')}
                  </p>
                </Split>

                <Link to={`/about`} className="butn bord curve mt-40 wow fadeInUp" data-wow-delay=".8s">
                  <span>{t('index.common.discover')}</span>
                </Link>

                <br />
              </div>
            </div>
          </div>
        </div>
      </section>
  );
};

export default MinimalArea2;
