import React from "react";
import { Link } from "gatsby";
import { useTranslation } from "react-i18next";
import cardMouseEffect from "common/cardMouseEffect";

const Services3 = () => {

  const { t } = useTranslation();

  React.useEffect(() => {
    cardMouseEffect(document.querySelectorAll(".feat .items"));
  }, []);

  return (
      <section className="feat sub-bg section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-10">
              <div className="sec-head">
                <h6 className="wow fadeIn" data-wow-delay=".5s">
                  {t("index.services.bestServices")}
                </h6>
                <h3 className="wow color-font">
                  {t("index.services.weBuildTools")}
                </h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6 items md-mb30">
              <div className="item wow fadeIn" data-wow-delay=".3s">
              <span className="icon">
                <i className="ion-ios-monitor"></i>
              </span>
                <h5>{t("index.services.applicationDevelopment")}</h5>
                <p>
                  {t("index.services.appDevelopmentDescription")}
                </p>
                <Link to="/about/about-dark" className="more-stroke">
                  <span></span>
                </Link>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 items active md-mb30">
              <div className="item wow fadeIn" data-wow-delay=".4s">
              <span className="icon">
                <i className="ion-ios-bolt-outline"></i>
              </span>
                <h5>{t("index.services.softwareConsulting")}</h5>
                <p>
                  {t("index.services.softwareConsultingDescription")}
                </p>
                <Link to="/about/about-dark" className="more-stroke">
                  <span></span>
                </Link>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 items sm-mb30">
              <div className="item wow fadeIn" data-wow-delay=".5s">
              <span className="icon">
                <i className="ion-cube"></i>
              </span>
                <h5>{t("index.services.softwareAsAService")}</h5>
                <p>
                  {t("index.services.softwareAsAServiceDescription")}
                </p>
                <Link to="/about/about-dark" className="more-stroke">
                  <span></span>
                </Link>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 items">
              <div className="item wow fadeIn" data-wow-delay=".6s">
              <span className="icon">
                <i className="ion-ios-color-wand"></i>
              </span>
                <h5>{t("index.services.researchAndDevelopment")}</h5>
                <p>
                  {t("index.services.researchAndDevelopmentDescription")}
                </p>
                <Link to="/about/about-dark" className="more-stroke">
                  <span></span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
  );
};

export default Services3;
