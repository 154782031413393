import React, {useEffect} from "react";

const Split = ({ children }) => {
  const target = React.createRef();

  const split = () => {
    if (target.current) {
      Splitting({ target: target.current });
    }
  };

  useEffect(() => {
    split();
  }, []);

    return <div ref={target}>{children}</div>;
}

export default Split;
